import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: "root"
})

export class UtilitySerice {


    constructor(@Inject(PLATFORM_ID) private platformId: any) { }

    getAuthToken() {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem("survey_admin_token");
        }
        // On the server, return some default value, like 1 or null
        return null;
    }

    clearStorage() {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem("survey_admin_token");
        }
    }

    trim(data: any) {
        for (const item in data) {
            if (typeof data[item] === "string") {
                data[item] = data[item].trim();
            }
        }
        return data;
    }

    setLocalStorage(key: string, value: string) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(key, value);
        }
    }
}

